<template>
  <v-dialog persistent v-model="dialog" max-width="400px">
    <v-card>
      <v-card-title> Remote Assistance - {{ this.status }} - {{ this.timer }} </v-card-title>

      <v-card-text>
        <video id="streaming-video" autoplay playsinline style="width: 350px;cursor: none"></video>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { throttle } from '../../functions/throttle';

export default {
  props: {
    value: Object,
  },
  data() {
    return {
      dialog: false,
      peerConnection: null,
      dataChannel: null,
      status: '',
      videoElement: null,
      deviceId: null,
      debounceMouseMove: null,
      timer: '',
      timerInstance: undefined
    }
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.close()
      }
    },
  },
  created() {
    this.$emit('input', {
      show: (data) => {
        this.show()

        if (data.deviceId) {
          this.deviceId = data.deviceId
          this.$nextTick(() => {
            this.startWebRTCConnection()
          })
        }
      },
      close: this.close,
    })

    this.$signalHub.$on('RemoteAssistanceAnswer', this.onRemoteAnswer)
    this.$signalHub.$on('RemoteAssistanceCandidate', this.onRemoteCandidate)
  },
  beforeDestroy() {
    this.$signalHub.$off('RemoteAssistanceAnswer', this.onRemoteAnswer)
    this.$signalHub.$off('RemoteAssistanceCandidate', this.onRemoteCandidate)

    this.clearWebRTCConnection();
  },
  methods: {
    show() {
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.deviceId = null

      this.clearWebRTCConnection();
      this.timer = ''
    },

    async startWebRTCConnection() {
      this.status = "initiating"

      this.videoElement = document.getElementById('streaming-video')
      this.setupEventListeners()

      this.peerConnection = new RTCPeerConnection({
        iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
      });

      await this.captureAudio()

      this.dataChannel = this.peerConnection.createDataChannel('control')
      this.dataChannel.onopen = () => {
        this.status = 'Data Channel Opened';
      };

      this.dataChannel.onmessage = (event) => {
        this.status`Data Received:`;
      };

      this.peerConnection.addEventListener('track', this.onTrack);
      this.peerConnection.addEventListener("icecandidate", this.onIceCandidate)
      this.peerConnection.addEventListener("icegatheringstatechange", this.onIceGatheringStateChange)
      this.peerConnection.addEventListener('iceconnectionstatechange', this.onIceConnectionStateChange)

      if (await this.createOffer()) {
        this.startCountdown(120)
      }
    },

    captureAudio() {
      return new Promise((resolve) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
          stream.getTracks().forEach((track) => {
            this.peerConnection.addTrack(track, stream);
          });
          resolve(true)
        }).catch(e => {
          resolve(false)
        })
      })
    },

    async createOffer() {
      const offer = await this.peerConnection.createOffer({
        offerToReceiveAudio: true,
        offerToReceiveVideo: true
      });

      await this.peerConnection.setLocalDescription(offer);
      const offerRes = await this.$signal.sendRemoteAssistanceOffer(this.deviceId, offer)
      this.status = offerRes.success ? "Connection starting" : "Device is Offline"
      return offerRes.success
    },

    onTrack(event) {
      this.status = "Processing Image"
      const stream = event.streams[0];

      if (this.videoElement) {
        this.videoElement.srcObject = stream;
      }
    },

    onIceGatheringStateChange() {
      if (this.peerConnection.iceGatheringState === 'gathering') {
        this.status = "gathering"
      } else if (this.peerConnection.iceGatheringState === 'complete') {
        this.status = "gathering complete"
      }
    },

    onIceConnectionStateChange() {
      if (
        this.peerConnection.iceConnectionState === "disconnected" ||
        this.peerConnection.iceConnectionState === "failed"
      ) {
        this.status = "disconnected or failed"
        this.clearWebRTCConnection();
      }
    },

    onIceCandidate(event) {
      if (!event.candidate) {
        return
      }

      this.status = "getting ice candidates"
      this.$signal.sendRemoteAssistanceCandidate(this.deviceId, event.candidate);
    },

    async onRemoteAnswer(answer) {
      console.log('onRemoteAnswer');

      await this.peerConnection.setRemoteDescription(answer);
      this.status = "Connected"
    },

    async onRemoteCandidate(candidate) {
      if (!this.peerConnection || !this.peerConnection.remoteDescription) {
        console.log('onRemoteCandidate: skipped');
        return
      }

      this.status = "remote candidate"
      await this.peerConnection.addIceCandidate(candidate);
    },

    async clearWebRTCConnection() {
      this.status = "closing"

      if (this.dataChannel) {
        this.dataChannel.close()
        this.dataChannel = null
      }

      if (this.peerConnection) {
        this.peerConnection.removeEventListener('track', this.onTrack);
        this.peerConnection.removeEventListener("icecandidate", this.onIceCandidate)
        this.peerConnection.removeEventListener("icegatheringstatechange", this.onIceGatheringStateChange)
        this.peerConnection.removeEventListener('iceconnectionstatechange', this.onIceConnectionStateChange)

        this.peerConnection.close();
        this.peerConnection = null;
      }

      if (this.videoElement) {
        this.removeEventListener()

        const stream = this.videoElement.srcObject;
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }

        this.videoElement.srcObject = null;
        this.videoElement.load();
      }

      this.status = "disconnected"
    },

    setupEventListeners() {
      this.debounceMouseMove = throttle(this.onMove, 30)

      this.videoElement.addEventListener('click', this.onClick)
      this.videoElement.addEventListener('mousemove', this.debounceMouseMove)
      document.addEventListener('keydown', this.onKeyDown)
    },

    removeEventListener() {
      this.videoElement.removeEventListener('click', this.onClick)
      this.videoElement.removeEventListener('mousemove', this.debounceMouseMove)
      document.removeEventListener('keydown', this.onKeyDown)
      this.debounceMouseMove = null
    },

    isFocused() {
      return this.videoElement != null && (document.activeElement === this.videoElement || document.activeElement.contains(this.videoElement));
    },

    onClick(e) {
      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        this.dataChannel.send(JSON.stringify({
          type: "click",
        }));
      }
    },

    onKeyDown(e) {
      if (!this.isFocused()) {
        return
      }

      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        this.dataChannel.send(JSON.stringify({
          type: "key",
          key: e.key
        }));
      }
    },

    onMove(e) {
      const x = e.clientX;
      const y = e.clientY;

      const targetRect = e.target.getBoundingClientRect();
      const targetWidth = targetRect.width
      const targetHeight = targetRect.height
      const targetTop = targetRect.top + window.scrollY;
      const targetLeft = targetRect.left + window.scrollX;

      const _x = x - targetLeft;
      const _y = y - targetTop;

      if (this.dataChannel && this.dataChannel.readyState === 'open') {
        this.dataChannel.send(JSON.stringify({
          type: "move",
          x: _x,
          y: _y,
          w: targetWidth,
          h: targetHeight
        }));
      }
    },
    startCountdown(time) {
      let remainingTime = time;
      clearInterval(this.timerInstance)

      this.timerInstance = setInterval(() => {
        if (remainingTime <= 0) {
          clearInterval(this.timerInstance);
          this.timer = ''
          return
        }

        this.timer = `${remainingTime}`
        remainingTime--;
      }, 1000);
    }
  },
}
</script>
