<template>
  <editable-config-table
    v-model="items"
    @update="update"
    @remove="remove"
  />
</template>

 <script>
  import EditableConfigTable from './config/EditableConfigTable.vue'
  import { get } from 'vuex-pathify'

  export default {
    components: { EditableConfigTable },
    props: {
      deviceId: Number,
    },
    data: () => ({
      items: [],
    }),

    computed: {
      ...get('company', ['selectedCompanyId', 'selectedCompanyName']),
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      deviceId () {
        this.items = []
        this.getDeviceConfigurations(this.deviceId).then(data => {
          this.items = data
        })
      },
    },

    created () {
      this.getDeviceConfigurations(this.deviceId).then(data => {
        this.items = data
      })
    },

    methods: {
      async getDeviceConfigurations (id) {
        if (!id || id === 0) {
          return
        }

        try {
          const res = await this.$http(`/DeviceConfiguration?deviceid=${id}`)
          if (res.data?.success) {
            return res.data.data || []
          }
        } catch (error) {

        }

        return []
      },
      update (item) {
        // if (!item.configurationId && item.configurationId === 0) {
        //   return
        // }

        // this.$http.put(`/DeviceConfiguration/${item.configurationId}`, item).then(response => {
        //   if (response.data.success) {
        //     this.$toast.success('Config updated successfully.', 'Success', {
        //       position: 'topRight',
        //     })
        //   } else {
        //     this.$toast.error(response.data.message, 'Error', {
        //       position: 'topRight',
        //     })
        //   }
        // })
      },
      remove (item) {
        // this.$http.delete(`/CompanyConfiguration/DeleteCompanyConfiguration?id=${item.id}`).then(response => {
        //   if (response.data.success) {
        //     this.$toast.success('Config removed successfully.', 'Success', {
        //       position: 'topRight',
        //     })

        //     const index = this.items.indexOf(item)
        //     this.items.splice(index, 1)
        //   } else {
        //     this.$toast.error(response.data.message, 'Error', {
        //       position: 'topRight',
        //     })
        //   }
        // })
      },
    },
  }
 </script>
